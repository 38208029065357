<template>
  <div>
    <!-- Menition Text -->
    <div class="form-group mt-2 mb-3">
      <div class="row">
        <div class="col mb-2">
          <label v-bind:for="componentId + '-text'" class="custom-label">{{
            title
          }}</label>
          <div class="d-block">
            <small class="text-muted"> {{ description }} </small>
          </div>
        </div>

        <div class="col-auto" v-if="showLimits">
          <span class="font-12">
            {{ currentTextLength }} / {{ textLength }}
          </span>
        </div>
      </div>
      <textarea
        v-if="!useEditor"
        v-bind:id="componentId + '-text'"
        class="form-control mb-2"
        style="min-height: 250px"
        v-model="computedData.text"
        v-bind:maxlength="textLength"
      ></textarea>
      <WeEditor
        v-else
        v-model="computedData.text"
        v-bind:mention-feed="mentions"
        v-bind:show-toolbar="false"
      />
    </div>
    <!-- Menition Text -->
  </div>
</template>

<script>
export default {
  name: "WeMentionData",
  data() {
    return {
      robotOptions: ["index", "noindex", "follow", "nofollow"],
    };
  },
  props: {
    data: {
      text: {
        default: "",
      },
    },
    title: {
      default: "",
    },
    description: {
      default: "",
    },
    textLength: {
      default: 350,
    },
    showLimits: {
      default: true,
    },
    useEditor: {
      default: false,
    },
    mentions: {
      default: () => [],
    },
  },
  model: {
    prop: "data",
  },
  methods: {},
  computed: {
    componentId() {
      return "wementiondata-" + helper.getRandomInteger(1, 999999);
    },
    computedData() {
      return this.data;
    },
    currentTextLength() {
      return this.computedData.text ? this.computedData.text.length : 0;
    },
  },
};
</script>

<style lang="scss">
.ck-content {
  .mention {
    background: #3490dc4d !important;
    color: var(--blue) !important;
    border: 1px solid var(--blue) !important;
    padding: 3px;
    font-size: 13px;
    border-radius: 0.25rem;
  }
}
.offer-info {
  span {
    margin-left: 20px !important;
  }
  &.ck-on {
    background: var(--teal) !important;
    span {
      color: var(--dark) !important;
    }
  }
}
</style>
